
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiRole } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-role-modal",
  components: {},
  emits: ["update-list"],
  props: {
    selectData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);
    const addRoleModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      name: "",
      note: "",
    });

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.status") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("permissions.roleName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiRole.addRole(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addRoleModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addRoleModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addRoleModalRef,
      submitButton,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
