
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
}

export default defineComponent({
  name: "filter-role-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  props: {
    selectData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      name: "",
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
    };
  },
});
